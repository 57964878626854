import * as React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import {
  mainContainer,
  mainContainerElement
} from '../../lib/whatsnew.module.css'

const WhatsNewPage = () => {
  return (
    <Layout pageTitle="What's New">
      <div className={mainContainer}>
        <div className={mainContainerElement}>
          <Link to="/whatsnew/finials">
            <StaticImage
              layout="CONSTRAINED"
              alt="Finials"
              src={"../../images/whatsnew/finials.jpg"}
              formats={["webp", "avif"]}
              placeholder="tracedSVG"
              quality="100"
            />
            <h2>Finials</h2>
          </Link>
        </div>
        <div className={mainContainerElement}>
          <Link to="/whatsnew/newbespoke">
            <StaticImage
              layout="CONSTRAINED"
              alt="NewBespoke"
              src={"../../images/whatsnew/bespoke.jpg"}
              formats={["webp", "avif"]}
              placeholder="tracedSVG"
              quality="100"
            />
            <h2>New Bespoke</h2>
          </Link>
        </div>
      </div>
   </Layout>
  )
}

export default WhatsNewPage